import React, { useEffect, useState, useCallback, useRef } from "react";

function CardSlider({ Items, maxItems }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeAutoplay, setActiveAutoplay] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const autoplayRef = useRef();

  const settings = {
    maxItems: maxItems,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const goTo = useCallback(
    (index) => {
      if (!isAnimating) {
        setCurrentIndex(index);
        setIsAnimating(true);

        setTimeout(() => {
          setIsAnimating(false);
        }, settings.speed);
      }
    },
    [isAnimating, currentIndex]
  );

  /* Go Next */
  const goNext = () => {
    goTo(currentIndex >= settings.maxItems - 1 ? 0 : currentIndex + 1);
  };

  /* Go Prev */
  const goPrev = () => {
    goTo(currentIndex <= 0 ? settings.maxItems - 1 : currentIndex - 1);
  };

  /* Play Timer */
  const playTimer = () => {
    setActiveAutoplay(true);
  };

  /* Pause Timer */
  const pauseTimer = () => {
    setActiveAutoplay(false);
  };

  useEffect(() => {
    if (settings.autoplay && activeAutoplay) {
      clearTimeout(autoplayRef.current);
      autoplayRef.current = setTimeout(() => {
        goNext();
      }, settings.autoplaySpeed);
    }
  }, [currentIndex, activeAutoplay, isAnimating]);

  const dots = () => {
    const totalItems = Array.apply(null, Array(settings.maxItems));
    const dotsButtons = totalItems.map((item, index) => {
      return (
        <div
          className={
            index === currentIndex
              ? "card-slider-pagination-item card-slider-pagination-item-active"
              : "card-slider-pagination-item"
          }
          key={index.toString()}
          onMouseEnter={pauseTimer}
          onMouseLeave={playTimer}
          onClick={() => goTo(index)}
        ></div>
      );
    });
    return <div className="card-slider-pagination">{dotsButtons}</div>;
  };

  const slide = (index) => {
    return (
      <a
        className="card-link"
        target="_blank"
        rel="noreferrer noopener"
        href={Items[index][1]}
      >
        <div className="card">
          <img src={Items[index][0]} alt="card-img"></img>
        </div>
      </a>
    );
  };

  return (
    <div className="card-slider">
      {dots()}
      {slide(currentIndex)}
    </div>
  );
}

export default CardSlider;
