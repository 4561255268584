import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./assets/styles/App.css";
import Main from "./pages/Main";
import Contact from "./pages/Contact";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="*" element={<Navigate replace to="/" />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/impressum" element={<Impressum />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
