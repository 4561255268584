import React, { useEffect } from "react";

function Faq({ props }) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const faqProps = props.faqData;

  const cardClicked = (e) => {
    var target = e.target;
    while (target.className !== "faq-grid-item") {
      target = target.parentElement;
    }
    if (target.getAttribute("data-type") === "extended") {
      target.removeAttribute("data-type", "extended");
    } else {
      target.setAttribute("data-type", "extended");
    }
  };

  return (
    <>
      <div className="plhl-container" data-type="faq">
        <div className="plhl-bg-trans padding-inline">
          <h1 className="plhl-container-header">{faqProps.title}</h1>
          <h2 className="plhl-container-sub-header">{faqProps.subTitle}</h2>
        </div>
        <div className="plhl-bg-img"></div>
      </div>
      <div className="padding-block-500 padding-inline">
        <div className="faq-grid">
          <div className="faq-grid-item" onClick={(e) => cardClicked(e)}>
            <div className="faq-grid-item-heading">
              <div className="faq-grid-item-header">
                {props.faqData.faqList[0].title}
              </div>
              <div className="faq-grid-item-control">
                <div className="faq-grid-item-control-text">&#43;</div>
              </div>
            </div>
            <div className="faq-grid-dropdown">
              <div className="faq-grid-content">
                <div className="faq-grid-content-text">
                  {props.faqData.faqList[0].content.text}
                </div>
              </div>
            </div>
          </div>

          <div className="faq-grid-item" onClick={(e) => cardClicked(e)}>
            <div className="faq-grid-item-heading">
              <div className="faq-grid-item-header">
                {props.faqData.faqList[1].title}
              </div>
              <div className="faq-grid-item-control">
                <div className="faq-grid-item-control-text">&#43;</div>
              </div>
            </div>
            <div className="faq-grid-dropdown">
              <div className="faq-grid-content">
                <div className="faq-grid-content-text">
                  {props.faqData.faqList[1].content.text}
                </div>
              </div>
            </div>
          </div>

          <div className="faq-grid-item" onClick={(e) => cardClicked(e)}>
            <div className="faq-grid-item-heading">
              <div className="faq-grid-item-header">
                {props.faqData.faqList[2].title}
              </div>
              <div className="faq-grid-item-control">
                <div className="faq-grid-item-control-text">&#43;</div>
              </div>
            </div>
            <div className="faq-grid-dropdown">
              <div className="faq-grid-content">
                <ul className="faq-grid-content-list">
                  {props.faqData.faqList[2].content.list.map((element) => {
                    return (
                      <li className="faq-grid-content-list-item">{element}</li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div className="faq-grid-item" onClick={(e) => cardClicked(e)}>
            <div className="faq-grid-item-heading">
              <div className="faq-grid-item-header">
                {props.faqData.faqList[3].title}
              </div>
              <div className="faq-grid-item-control">
                <div className="faq-grid-item-control-text">&#43;</div>
              </div>
            </div>
            <div className="faq-grid-dropdown">
              <div className="faq-grid-content">
                <div className="faq-grid-content-subTitle">
                  {props.faqData.faqList[3].content.subTitle[0]}
                </div>
                <div className="faq-grid-content-text">
                  {props.faqData.faqList[3].content.text[0]}
                </div>
                <div className="faq-grid-content-text">
                  {props.faqData.faqList[3].content.text[1]}
                </div>

                <span className="faq-extra-text-container">
                  <div className="faq-grid-content-subTitle">
                    {props.faqData.faqList[3].content.subTitle[1]}
                  </div>
                  <div className="faq-grid-content-highlight">
                    {props.faqData.faqList[3].content.extra}
                  </div>
                </span>
                <div className="faq-grid-content-text">
                  {props.faqData.faqList[3].content.text[2]}
                </div>

                <div className="faq-grid-content-text">
                  {props.faqData.faqList[3].content.text[3]}
                </div>
              </div>
            </div>
          </div>

          <div className="faq-grid-item" onClick={(e) => cardClicked(e)}>
            <div className="faq-grid-item-heading">
              <div className="faq-grid-item-header">
                {props.faqData.faqList[4].title}
              </div>
              <div className="faq-grid-item-control">
                <div className="faq-grid-item-control-text">&#43;</div>
              </div>
            </div>
            <div className="faq-grid-dropdown">
              <div className="faq-grid-content">
                <div className="faq-grid-content-subTitle">
                  {props.faqData.faqList[4].content.subTitle}
                </div>
                <div className="faq-grid-content-text">
                  {props.faqData.faqList[4].content.text[0]}
                </div>
                <div className="faq-grid-content-text">
                  {props.faqData.faqList[4].content.text[1]}
                  <span className="faq-grid-content-highlight">
                    {props.faqData.faqList[4].content.extra[0]}
                  </span>
                </div>

                <div className="faq-grid-content-text">
                  {props.faqData.faqList[4].content.text[2]}
                </div>
                <div className="faq-grid-content-text">
                  {props.faqData.faqList[4].content.text[3]}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
