import React from "react";
import { useMediaQuery } from "react-responsive";
import PersonalCardSlider from "../PersonalCardSlider";

function Team({ props }) {
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  const teamProps = props.teamData;
  const pflege = teamProps.pflegeTeam;
  const aerzte = teamProps.aerzteTeam;
  const kaufmann = teamProps.kaufmannTeam;

  var iconPflege = require("../../assets/img/" + pflege.icon);
  var iconAerzte = require("../../assets/img/" + aerzte.icon);
  var iconKaufmann = require("../../assets/img/" + kaufmann.icon);

  return (
    <div className="padding-block-700 padding-inline">
      {isMobile ? (
        <PersonalCardSlider Items={[pflege, aerzte, kaufmann]} maxItems={3} />
      ) : (
        <div className="team-list">
          <div className="team-list-item">
            <img
              src={iconPflege}
              alt="team_icon"
              className="team-list-item-icon"
            ></img>
            <div className="team-list-item-header">{pflege.title}</div>
            <ul className="team-list-item-personal">
              {pflege.list.map((item) => {
                return <li className="team-list-item-personal-item">{item}</li>;
              })}
            </ul>
          </div>

          <div className="team-list-item">
            <img
              src={iconAerzte}
              alt="team_icon"
              className="team-list-item-icon"
            ></img>
            <div className="team-list-item-header">{aerzte.title}</div>
            <ul className="team-list-item-personal">
              {aerzte.list.map((item) => {
                return <li className="team-list-item-personal-item">{item}</li>;
              })}
            </ul>
          </div>

          <div className="team-list-item">
            <img
              src={iconKaufmann}
              alt="team_icon"
              className="team-list-item-icon"
            ></img>
            <div className="team-list-item-header">{kaufmann.title}</div>
            <ul className="team-list-item-personal">
              {kaufmann.list.map((item) => {
                return <li className="team-list-item-personal-item">{item}</li>;
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Team;
