import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { HashLink } from "react-router-hash-link";
import hrBg from "../../assets/img/PHOTO-2024-01-09-13-09-15.webp";
import hrBgMobile from "../../assets/img/PHOTO-2024-01-09-13-09-15_mobile.webp";
import hrConnectorImg from "../../assets/img/hr-conntector.webp";

function Hero({ props }) {
  const heroProps = props.heroData;
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  const onClickNav = (e, id) => {
    e && e.preventDefault(); // to avoid the link from redirecting
    var elementToView = document.getElementById(id).scrollIntoView();
  };

  return (
    <>
      <div className="hr-bg-wrapper">
        <div className="hr-content-area">
          <div className="even-areas">
            <div className="hr-content-wrapper">
              <h1 className="hr-content-title">
                <p>{heroProps.title[0]}</p>
                <p>{heroProps.title[1]}</p>
                <p>{heroProps.title[2]}</p>
              </h1>
              <h2 className="hr-content-sub-title">{heroProps.subTitle}</h2>
              <HashLink
                className="btn"
                onClick={(e) => onClickNav(e, "kontakt")}
              >
                {heroProps.btn}
              </HashLink>
            </div>
            {isMobile ? (
              <></>
            ) : (
              <img
                className="hr-img-connector"
                src={hrConnectorImg}
                alt="heroFgImg"
              ></img>
            )}
          </div>
        </div>
        {isMobile ? (
          <img
            className="hr-img-connector"
            src={hrConnectorImg}
            alt="heroFgImg"
          ></img>
        ) : (
          <></>
        )}

        <div className="hr-bg-trans"></div>
        <img
          className="hr-bg-img"
          src={isMobile ? hrBgMobile : hrBg}
          alt="hero-bg"
        ></img>
      </div>
    </>
  );
}

export default Hero;
