const props = {
  navbarData: {
    mainContent: {
      links: [
        {
          link: "/#top",
          text: "WILLKOMMEN",
        },
        {
          link: "/informationen-patienten",
          text: "Informationen für Patienten",
        },
        {
          link: "/informationen-fachpersonal",
          text: "Informationen für Fachpersonal",
        },
        {
          link: "/#team",
          text: "Unser SAPV-Team",
        },
        {
          link: "https://hausleben-kurstadtregion.de/",
          text: "Unser Träger HausLeben e.V.",
        },
      ],
    },
    upperNavbarContent: {
      data: {
        tel: {
          text: "035365-398351",
          icon: "",
        },
        mail: {
          text: "info@sapv-hausleben.de",
          icon: "",
        },
        emergency: {
          text: "035365-398350",
          icon: "",
        },
        openingTimes: {
          text: ["Büro", "besetzt"],
          icon: "",
        },
      },
    },
  },

  heroData: {
    title: [
      '"Wir können den Wind nicht ändern,',
      'aber die Segel anders setzen"',
      "(Aristoteles)",
    ],
    subTitle:
      "Wir behandeln und betreuen schwerstkranke Menschen in ihrer vertrauten Umgebung",
    btn: "Kontaktieren Sie uns",
  },

  serviceData: {
    title: "Unsere Leistungen beinhalten:",
    serviceList: [
      "Versorgung und Betreuung in vertrauter Umgebung",
      "Erstellung eines individuellen Behandlungsplanes",
      "Ruf- und Einsatzbereitschaft rund um die Uhr",
      "Enge Zusammenarbeit mit Haus-/Fach-/ Krankenhausärzten und Pflegediensten",
      "Schmerztherapeutische Behandlung",
      "Symptomlinderung durch Medikamente und andere Maßnahmen",
      "Vorbeugende Maßnahmnen zur Krisenbewältigung",
    ],
  },

  notrufPlaceholder: {
    title: "Für unsere Patienten sind wir rund um die Uhr erreichbar.",
    btn: "Kontaktieren Sie Uns",
  },

  teamData: {
    pflegeTeam: {
      title: "Pflegeteam",
      icon: "pflegeIcon.webp",
      list: [
        "Verena Blaas",
        "Miriam Hiller",
        "Thomas Kubo",
        "Doreen Paul-Haase",
        "Doreen Werner",
      ],
    },
    aerzteTeam: {
      title: "Ärzteteam",
      icon: "aerzteIcon.webp",
      list: [
        "Claudia König",
        "Sandra Stolpe",
        "Dr. med. Erhard Kiesel",
        "Dr. med. Sebastian Zietz",
      ],
    },
    kaufmannTeam: {
      title: "Kaufmännische Leitung",
      icon: "kaufmannIcon.webp",
      list: [
        "Anke Boeltzig (Geschäftsführung)",
        "Uwe Schrader (Geschäftsführung)",
        "Anja Gemeinhardt (kfm. Leitung)",
      ],
    },
  },

  faqData: {
    title: "Fragenkatalog",
    subTitle: "Für Patienten, Angehörige und Fachpersonal",
    faqList: [
      {
        title: "Was ist SAPV?",
        content: {
          text: "SAPV steht für Spezialisierte Ambulante Palliativversorgung. Diese Versorgung wird durch das Palliativteam erbracht, in dem unterschiedliche Berufsgruppen mit einer speziellen palliativen Ausbildung ambulant zusammenarbeiten (Palliativmediziner, PalliativCare Fachkräfte und Kooperationspartner).",
        },
      },
      {
        title: "Wann zur SAPV?",
        content: {
          text: "Diese spezielle ergänzende (additive) Leistung richtet sich an schwerstkranke Menschen, deren Erkrankung die Lebenszeit verkürzt und vor allem die Lebensqualität durch ein komplexes Symptomgeschehen (wie z.B. Schmerzen, Atemnot, Übelkeit, Schwäche, etc.) deutlich einschränkt. Die SAPV-Leistungen sind indiziert unabhängig davon, ob eine onkologische, internistische, neurologische, psychiatrische oder anderweitige Erkrankung zugrunde liegt.",
        },
      },
      {
        title: "Ziele der SAPV",
        content: {
          list: [
            "kurative Ansätze treten in den Hintergrund (dennoch weiter möglich!)",
            "Förderung der Lebensqualität und Selbstbestimmung",
            "Unterstützung der Selbsthilfefähigkeit des sozialen Umfeldes",
            "Mittelpunkt: individuelle Bedürfnisse/Wünsche von Patienten (und Angehörigen)",
            "menschenwürdiges Leben bis zum Tod",
            "Betreuung auch im Seniorenheim, Hospiz etc.",
          ],
        },
      },
      {
        title: "Warum SAPV?",
        content: {
          subTitle: ["Ergänzung!", "Entlastung !"],
          text: [
            "Wir kommen, wenn man uns braucht - nur dann! Gemäß Vertrag mit den Krankenkassen dürfen wir nur bei fortbestehenden Beschwerden oder besonderem Betreuungsbedarf diese spezialisierte Betreuung erbringen. Wir ergänzen den Haus- bzw. Facharzt und alle weiteren Dienste immer dann, wenn die Versorgung besonders aufwendig und komplex wird.",
            "Sollte der Bedarf sich ändern, ziehen wir uns gegebenenfalls auch wieder zurück.",
            "Um in der letzten Lebensphase ein Verbleiben zu Hause oder im Pflegeheim zu gewährleisten, sowie zur Vermeidung von Notarzteinsätzen und Klinikeinweisungen, sind wir 24 Stunden erreichbar und einsatzbereit. Damit stellen wir eine fachkompetente Versorgung rund um die Uhr - telefonisch und persönlich vor Ort - sicher.",
            "Alle notwendigen Verordnungen für die palliative Versorgung, wie Medikamente, Heil- und Hilfsmittel (z.B. Schmerzpumpen oder Physiotherapie) erfolgen durch das Palliativteam selbst (extrabudgetär). Hier können wir u.a. den behandelnden Arzt entlasten. Die bekannte haus- und oder fachärztliche Behandlung der Patienten wird weitergeführt.",
          ],
          extra: "-durch 24h Rufbereitschaft-",
        },
      },
      {
        title: "Wie SAPV?",
        content: {
          subTitle: "Verordnung",
          text: [
            "Damit unser Palliativteam (SAPV) HausLeben tätig werden kann, wird eine Verordnung durch den Haus-, Fach- oder Krankenhausarzt benötigt. Nur dann übernehmen die Krankenkassen die Kosten. Für die Patienten entstehen keine zusätzlichen Kosten und das Arztbudget wird nicht belastet.",
            "Behandelnde Ärzte, die die Verordnung der SAPV bei einem Patienten mit palliativer Erkrankung für erforderlich halten, nehmen einfach mit uns Kontakt auf: ",
            "Wir sind bestrebt, zeitnah Ihren Patienten in die SAPV-Betreuung zu beraten und gegebenenfalls aufzunehmen. Die Verordnung der SAPV erfolgt auf dem offiziellen KBV-Formular 63.",
            "Bei einer mündlichen Vorabbeauftragung durch den Haus- oder Facharzt und nach Kontaktaufnahme mit dem Patienten bzw. den Angehörigen legen wir gerne gemeinsam den tatsächlichen SAPV-Bedarf fest.",
          ],
          extra: ["035365-398351 (Büro)"],
        },
      },
    ],
  },

  networkData: {
    title: "Unser Netzwerk",
    partnerList: [
      {
        link: "",
        partner: "",
        img: "",
      },
    ],
  },

  footerData: {
    links: [
      {
        link: "/kontakt",
        text: "Kontakt",
      },
      {
        link: "/impresum",
        text: "Impressum",
      },
      {
        link: "/datenschutz",
        text: "Datenschutz",
      },
    ],
    contactData: {
      address: "Bahnhofstraße 4 | 04895 Falkenberg/Elster",
      tel: "035365-398351",
      fax: "035365-398355",
      mail: "info@sapv-hausleben.de",
      emergency: "035365-398350",
      openingTimes: "8:00 - 15:00 Uhr (Mo.- Fr.)",
    },
  },
};

export default props;
