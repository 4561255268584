import React from "react";
import CardSlider from "../CardSlider";
import logoDoceins from "../../assets/img/Logo_Doceins.webp";
import logoMedifalk from "../../assets/img/Logo_Medifalk.webp";
import schaubild1 from "../../assets/img/Schaubild_1.webp";

function Network() {
  const memberList = [
    [logoDoceins, "https://doceins.de/"],
    [logoMedifalk, "https://medifalk.de/"],
  ];

  return (
    <div className="padding-block-700">
      <h1 className="network-header">Unser Netzwerk</h1>
      {/* <CardSlider Items={memberList} maxItems={2} /> */}
      <div className="team-img-group">
        <img src={schaubild1} alt="Schaubild 1"></img>
      </div>
    </div>
  );
}

export default Network;
