import React from "react";
import { useMediaQuery } from "react-responsive";
import { HashLink } from "react-router-hash-link";
import props from "../data/GermanData";
import logo from "../assets/img/logoHausLeben_white.webp";

function Footer() {
  const YEAR = new Date().getFullYear();
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  return (
    <footer id="kontakt">
      <div className="primary-footer-wrapper">
        <div className="primary-footer-links">
          <img
            className="primary-footer-links-logo"
            src={logo}
            alt="Logo"
          ></img>
          <div className="primary-footer-links-content">
            <div className="primary-footer-links-content-text">
              {"© " + YEAR + " HausLeben Kurstadtregion e. V."}
            </div>
            <div className="primary-footer-links-content-text">
              {props.footerData.contactData.address}
            </div>
            <div className="primary-footer-links-content-text">
              {props.footerData.contactData.openingTimes}
            </div>
            {isMobile ? (
              <>
                <div className="primary-footer-links-content-text">
                  {"Tel: " + props.footerData.contactData.tel}
                </div>
                <div className="primary-footer-links-content-text">
                  {"Fax: " + props.footerData.contactData.fax}
                </div>
              </>
            ) : (
              <div className="primary-footer-links-content-contact">
                <div className="primary-footer-links-content-text">
                  {"Tel: " + props.footerData.contactData.tel}
                </div>
                <div className="primary-footer-links-content-text">
                  {"Fax: " + props.footerData.contactData.fax}
                </div>
                <div className="primary-footer-links-content-text">
                  {props.footerData.contactData.mail}
                </div>
              </div>
            )}

            <div className="primary-footer-links-content-emergency">
              <span>Notfallnummer: </span>
              {props.footerData.contactData.emergency}
            </div>
          </div>
        </div>
        <div className="primary-footer-nav">
          <HashLink to="/impressum">Impressum</HashLink>
          <HashLink to="/datenschutz">Datenschutz</HashLink>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
