import React, { Component } from "react";

export default class Contact extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "instant" });
  }

  render() {
    return <div>Contact</div>;
  }
}
