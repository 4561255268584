import React, { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import props from "../data/GermanData";
import { HashLink } from "react-router-hash-link";
import burgerMenu from "../assets/img/burgerMenu.webp";
import logo from "../assets/img/logoHausLeben.webp";
import openingTimes from "../data/openingTimes.json";
import clockIcon from "../assets/img/clock_icon.webp";
import phoneIcon from "../assets/img/contact_phone_icon.webp";
import mailIcon from "../assets/img/mail_icon.webp";
import emergencyIcon from "../assets/img/notruf_icon.webp";
import pdfPatienten from "../assets/pdf/Informationen_fuer_Patienten_und_Angehoerige.pdf";
import pdfFachpersonal from "../assets/pdf/Informationen_fuer_Fachpersonal.pdf";

function Navbar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showDesktopMenu, setShowDesktopMenu] = useState(false);
  const [currentOfficeTime, setCurrentOfficeTime] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  useEffect(() => {}, []);

  const openMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    setMobileBurgerState(!showMobileMenu);
  };

  const linkClicked = () => {
    setDesktopBurgerState(false);
    setShowDesktopMenu(false);
  };

  const openDesktopMenu = () => {
    setShowDesktopMenu(!showDesktopMenu);

    if (!showDesktopMenu) {
      setDesktopBurgerState(true);
    } else {
      setDesktopBurgerState(false);
    }
  };

  const setDesktopBurgerState = (setOpen) => {
    let desktopMenu = document.querySelector(".nav-dektop");
    setBurgerMenuState(desktopMenu, setOpen);
  };

  const setMobileBurgerState = (setOpen) => {
    let mobileMenu = document.querySelector(".nav-mobile");
    setBurgerMenuState(mobileMenu, setOpen);
  };

  const setBurgerMenuState = (burgerMenu, setOpen) => {
    const burgerMenuTop = burgerMenu.children[0];
    const burgerMenuCenter = burgerMenu.children[1];
    const burgerMenuBottom = burgerMenu.children[2];

    if (setOpen) {
      burgerMenuTop.setAttribute("data-type", "toX");
      burgerMenuCenter.setAttribute("data-type", "toX");
      burgerMenuBottom.setAttribute("data-type", "toX");
    } else {
      burgerMenuTop.setAttribute("data-type", "reverse");
      burgerMenuCenter.setAttribute("data-type", "reverse");
      burgerMenuBottom.setAttribute("data-type", "reverse");
    }
  };

  const onClickNav = (e, id) => {
    e && e.preventDefault(); // to avoid the link from redirecting
    if (isMobile) {
      onNavLinkClicked();
    } else {
      linkClicked();
    }
    var elementToView = document.getElementById(id).scrollIntoView();
  };

  const scrollToTop = () => {
    if (isMobile) {
      onNavLinkClicked();
    } else {
      linkClicked();
    }
    window.scrollTo(0, 0);
  };

  const onNavLinkClicked = () => {
    setShowMobileMenu(false);
  };

  const MobileMenu = () => {
    if (showMobileMenu) {
      return (
        <div className="nav-mobile-menu">
          <div className="nav-mobile-links">
            <HashLink className="nav-link" onClick={(e) => scrollToTop()}>
              Start
            </HashLink>

            <HashLink
              className="nav-link"
              onClick={(e) => onClickNav(e, "services")}
            >
              <span>Unsere Leistungen</span>
            </HashLink>

            <HashLink
              className="nav-link"
              onClick={(e) => onClickNav(e, "team")}
            >
              <span>Unser Team</span>
            </HashLink>

            <HashLink
              className="nav-link"
              onClick={(e) => onClickNav(e, "faq")}
            >
              <span>Fragenkatalog</span>
            </HashLink>

            <HashLink
              className="nav-link"
              to={pdfPatienten}
              target="_blank"
              rel="noreferrer noopener"
            >
              <span>Informationen für Patienten</span>
            </HashLink>

            <HashLink
              className="nav-link"
              to={pdfFachpersonal}
              target="_blank"
              rel="noreferrer noopener"
            >
              <span>Informationen für Fachpersonal</span>
            </HashLink>

            <HashLink
              className="nav-link"
              target="_blank"
              rel="noreferrer noopener"
              to="https://hausleben-kurstadtregion.de/"
            >
              <span>Unser Träger Hausleben e.V.</span>
            </HashLink>

            <HashLink
              className="nav-link"
              onClick={(e) => onClickNav(e, "kontakt")}
            >
              <span>Kontakt</span>
            </HashLink>

            <div className="horizontal-line"></div>

            <div className="nav-info-panel">
              <div className="nav-info-panel-item">
                <img
                  className="nav-info-panel-item-img"
                  alt="Öffnungszeiten Icon"
                  src={clockIcon}
                ></img>
                <div className="nav-info-panel-item-text">
                  8:00 - 15:00 Uhr (Mo.- Fr.)
                </div>
              </div>

              <div className="nav-info-panel-item">
                <img
                  className="nav-info-panel-item-img"
                  alt="E-Mail Icon"
                  src={mailIcon}
                ></img>
                <div className="nav-info-panel-item-text">
                  {props.navbarData.upperNavbarContent.data.mail.text}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return;
  };

  const DesktopMenu = () => {
    if (showDesktopMenu) {
      return (
        <div className="nav-desktop-menu">
          <div className="nav-desktop-menu-links">
            <HashLink
              className="nav-desktop-menu-link"
              onClick={(e) => scrollToTop()}
            >
              Start
            </HashLink>

            <HashLink
              className="nav-desktop-menu-link"
              onClick={(e) => onClickNav(e, "services")}
            >
              Unsere Leistungen
            </HashLink>

            <HashLink
              className="nav-desktop-menu-link"
              onClick={(e) => onClickNav(e, "team")}
            >
              Unser Team
            </HashLink>

            <HashLink
              className="nav-desktop-menu-link"
              onClick={(e) => onClickNav(e, "faq")}
            >
              Fragenkatalog
            </HashLink>

            <HashLink
              className="nav-desktop-menu-link"
              to={pdfPatienten}
              target="_blank"
              rel="noreferrer noopener"
            >
              Informationen für Patienten
            </HashLink>

            <HashLink
              className="nav-desktop-menu-link"
              to={pdfFachpersonal}
              target="_blank"
              rel="noreferrer noopener"
            >
              Informationen für Fachpersonal
            </HashLink>

            <HashLink
              className="nav-desktop-menu-link"
              target="_blank"
              rel="noreferrer noopener"
              to="https://hausleben-kurstadtregion.de/"
            >
              Unser Träger Hausleben e.V.
            </HashLink>

            <HashLink
              className="nav-desktop-menu-link"
              onClick={(e) => onClickNav(e, "kontakt")}
            >
              Kontakt
            </HashLink>
          </div>
        </div>
      );
    }
  };

  return (
    <header>
      <div className="header-container">
        <div className="primary-header">
          <div className="nav">
            <HashLink
              onClick={(e) => {
                scrollToTop();
              }}
            >
              <img className="nav-logo" src={logo} alt="logo" />
            </HashLink>
            <div className="nav-links">
              <div className="nav-mobile" onClick={(e) => openMobileMenu()}>
                <div className="nav-burger-icon-upper nav-burger-icon"></div>
                <div className="nav-burger-icon-center nav-burger-icon"></div>
                <div className="nav-burger-icon-lower nav-burger-icon"></div>
              </div>
              <div className="nav-dektop" onClick={(e) => openDesktopMenu()}>
                <div className="nav-burger-icon-upper nav-burger-icon"></div>
                <div className="nav-burger-icon-center nav-burger-icon"></div>
                <div className="nav-burger-icon-lower nav-burger-icon"></div>
              </div>
            </div>
          </div>
          <div className="nav-lower-links-container">
            <div className="nav-lower-links">
              <HashLink
                className="nav-link-wrapper"
                onClick={(e) => scrollToTop()}
              >
                <div className="nav-link-hover"></div>
                <div className="nav-link">Start</div>
                <div className="nav-link-hover-line"></div>
              </HashLink>

              <HashLink
                className="nav-link-wrapper"
                onClick={(e) => onClickNav(e, "services")}
              >
                <div className="nav-link-hover"></div>
                <div className="nav-link"> Unsere Leistungen</div>
                <div className="nav-link-hover-line"></div>
              </HashLink>

              <HashLink
                className="nav-link-wrapper"
                onClick={(e) => onClickNav(e, "faq")}
              >
                <div className="nav-link-hover"></div>
                <div className="nav-link">Fragenkatalog</div>
                <div className="nav-link-hover-line"></div>
              </HashLink>

              <HashLink
                className="nav-link-wrapper"
                to={pdfPatienten}
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="nav-link-hover"></div>
                <div className="nav-link">Informationen für Patienten</div>
                <div className="nav-link-hover-line"></div>
              </HashLink>

              <HashLink
                className="nav-link-wrapper"
                to={pdfFachpersonal}
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="nav-link-hover"></div>
                <div className="nav-link">Informationen für Fachpersonal</div>
                <div className="nav-link-hover-line"></div>
              </HashLink>

              <HashLink
                className="nav-link-wrapper"
                target="_blank"
                rel="noreferrer noopener"
                to="https://hausleben-kurstadtregion.de/"
              >
                <div className="nav-link-hover"></div>
                <div className="nav-link">Unser Träger Hausleben e.V.</div>
                <div className="nav-link-hover-line"></div>
              </HashLink>

              <HashLink
                className="nav-link-wrapper"
                onClick={(e) => onClickNav(e, "kontakt")}
              >
                <div className="nav-link-hover"></div>
                <div className="nav-link">Kontakt</div>
                <div className="nav-link-hover-line"></div>
              </HashLink>
            </div>
          </div>
          <MobileMenu />
          <DesktopMenu />
        </div>
      </div>
    </header>
  );
}

export default Navbar;
