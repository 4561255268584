import React, { useEffect } from "react";

function Services({ props }) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  const serviceProps = props.serviceData;

  return (
    <div className="padding-block-700 padding-inline">
      <h1 className="service-header">{serviceProps.title}</h1>
      <ul className="service-list">
        {serviceProps.serviceList.map((service) => {
          return <li className="service-list-item">{service}</li>;
        })}
      </ul>
    </div>
  );
}

export default Services;
